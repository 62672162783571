<template>
<div class="column has-text-left">
  <div class="testim-panel">
    <font-awesome-icon :icon="['fal', 'quote-left']" class="has-text-grey-dark fa-2x"/>
<!--    <div class="testim-panel-text content" v-html="pageData.fields.htmlText"></div>-->
    <rich-text :document="pageData.fields.richText" class="testim-panel-text" :background="background"></rich-text>
    <font-awesome-icon :icon="['fal', 'quote-right']" class="has-text-grey-dark fa-2x" pull="right"/>
  </div>
  <div class="has-text-centered">
    <div class=" is-size-6 has-text-weight-semibold" v-html="pageData.fields.personReference.fields.name"></div>
    <div class=" is-size-6 has-text-weight-semibold has-dbl-space-below" v-html="pageData.fields.personReference.fields.company"></div>
    <figure class="image is-inline-block" v-if="pageData.fields.panelImage">
      <img
          :sizes="'(max-width: 768px) calc((100vw - 64px) / 3), calc((852px - '+(size - 1)+' * 30px) / 6)'"
          :srcset="`${panelImageURL}?w=320&h=320&fit=scale 320w,
                      ${panelImageURL}?w=640&h=640&fit=scale 640w,
                      ${panelImageURL}?w=960&h=960&fit=scale 960w`"
          :src="panelImageURL + '?w=640&h=640&fit=scale'"
          alt="Company icon"
      >
    </figure>
  </div>
</div>
</template>

<script>
  import RichText from "@/components/RichText";

  export default {
    name: 'TestimonialPanel',
    props: {
      pageData: Object,
      background: String,
      size: Number
    },
    components: {
      RichText
    },
    computed: {
      panelImageURL() {
        let f = this.pageData.fields;
        if (f.panelImage) {
          f = f.panelImage.fields.file.url;
          // f += "?fit=pad&w=128&h=128"; // resize to 128 by 128
        }
        else {
          f = "../assets/images/missing.jpg "
        }
        this.$log.debug("{TestimonialPanel} f:", f);
        //this.$log.debug("{TestimonialPanel} this.pageData.fields:", this.pageData.fields);
        return f;
      },
      panelDescripton(){
        let f = this.pageData.fields;
        if (f.panelImage) {
          f = f.panelImage.fields.description;
        }
        else {
          f = "missing..."
        }
        return f;
      }
    }
  }
</script>



<!--DOM content created with v-html are not affected by scoped styles, but you can still style them using deep selectors.-->
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  /*@import "~bulma/sass/utilities/functions";
@import "~bulma/sass/utilities/initial-variables";
@import "~bulma/sass/utilities/derived-variables";
@import "~bulma/sass/utilities/mixins";*/
  /*@import "@/scss/_bulmaOverridden";*/

  div.testim-panel {
    min-height: 20em;
    padding-bottom: 1em;
  }

  div.testim-panel-text {
    padding-left: 2em;
    padding-right: 2em;
    margin-bottom: 0;
  }


</style>
